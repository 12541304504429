<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="20px" :rules="rules" style="width: 98%">
            <el-form-item :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
              <el-table :data="form.details" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
                <el-table-column prop="name" label="药品名称/条形码" width="260">
                  <template slot-scope="scope">
                    <el-form-item label="" :prop="'details[' + scope.$index + '].entityId'" :rules="rules.entityId">
                      <el-select class="selectDrug" size="small" v-model="scope.row.entityId" filterable remote clearable placeholder="药品名称/商品名/条形码/别名" :remote-method="remoteMethod" :loading="isLoading" @change="handleSelect($event, scope.row)">
                        <el-option v-for="item in options" :key="item.id" :label="`${item.mzDrug.drugName}${item.mzDrug.drugType == '' ? '' : item.mzDrug.drugType == 'T' ? '(统)' : item.mzDrug.drugType == 'X' ? '(选)' : '(精选)'}(${item.specification}/${item.specificationUnit}${item.productFactory ? ' - ' + item.productFactory : ''})`" :value="item.id"> </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="name" align="guidancePrice" label="药品规格">
                  <template slot-scope="scope">
                    <p v-if="scope.row.specification" style="height: 40px">{{ scope.row.specification }}/{{ scope.row.specificationUnit }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="productFactory" label="厂家">
                  <template slot-scope="scope">
                    <p style="height: 40px">{{ scope.row.productFactory }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="进货价">
                  <template slot-scope="scope">
                    <el-form-item label="">
                      <el-input-number v-model="scope.row.purchasePrice" size="small" controls-position="right" :precision="2" :step="0.1" :min="0" class="leftInput" disabled></el-input-number>
                      <span class="rightTip" style="right: 38px">
                        <span>元/{{ scope.row.specificationUnit }}</span>
                      </span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="单价">
                  <template slot-scope="scope">
                    <el-form-item label="">
                      <el-input-number v-model="scope.row.price" size="small" controls-position="right" :precision="2" :step="0.1" :min="0" class="leftInput" disabled></el-input-number>
                      <span class="rightTip" style="right: 38px">
                        <span v-if="scope.row.isSeparate">元/{{ scope.row.separateNumberUnit }}</span>
                        <span v-else>元/{{ scope.row.specificationUnit }}</span>
                      </span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="money" label="售价">
                  <template slot-scope="scope">
                    <el-form-item label="" :prop="'details[' + scope.$index + '].money'" :rules="rules.money">
                      <el-input-number v-model="scope.row.money" size="small" controls-position="right" :precision="2" :step="1" :min="0.01" class="leftInput" :disabled="!scope.row.entityName"></el-input-number>
                      <span class="rightTip" style="right: 38px">
                        <span v-if="scope.row.isSeparate">元/{{ scope.row.separateNumberUnit }}</span>
                        <span v-else>元/{{ scope.row.specificationUnit }}</span>
                      </span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="开药量" width="150">
                  <template slot-scope="scope">
                    <el-form-item label="" :prop="'details[' + scope.$index + '].amount'" :rules="rules.amount">
                      <el-input-number v-model="scope.row.amount" size="small" controls-position="right" :precision="0" :step="1" :min="1" class="leftInput selectDrug" :disabled="!scope.row.entityName"></el-input-number>
                      <span class="rightTip" style="right: 38px">
                        <span v-if="scope.row.isSeparate">{{ scope.row.separateNumberUnit }}</span>
                        <span v-else>{{ scope.row.specificationUnit }}</span>
                      </span>
                      <i class="warningTip el-icon-info" v-if="scope.row.entityName && scope.row.amount > scope.row.inventoryNumber"> </i>
                      <span class="tipMsg">库存不足({{ scope.row.inventoryNumber ? scope.row.inventoryNumber : 0 }})</span>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="countMoney" label="金额（元）" width="160px">
                  <template slot-scope="scope">
                    <p style="height: 40px">{{ scope.row.money * scope.row.amount ? (scope.row.money * scope.row.amount).toFixed(2) : '0.00' }}</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" width="60px">
                  <template slot-scope="scope">
                    <el-form-item label="">
                      <el-button type="danger" size="mini" plain @click="handleDelete(scope.row, scope.$index)">
                        <i class="el-icon-delete" aria-hidden="true"></i>
                      </el-button>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
              <div style="padding: 0 10px">
                <span class="primary optionBtn" @click="handleAdd()">添加药品</span>
                <span class="warning optionBtn" @click="handleReset()">重置</span>
              </div>
            </el-form-item>
          </el-form>
          <div class="numDiv flex-end">
            <!-- <div class="">总金额：￥{{ form.totalMoney ? form.totalMoney.toFixed(2) : '0.00' }} <span class="num1">-</span>
              优惠金额：</div>
            <div class="inputNum">￥<el-input-number v-model="form.discountsMoney" size="small" controls-position="right"
                :precision="2" :step="1" :min="0" :max="form.totalMoney" class="num2"></el-input-number></div> -->
            <div class="num1">应收金额:</div>
            <div class="inputNum" style="margin-right: 50px">
              ￥
              <!-- <span style="font-size: 25px;" v-if="form.discountsMoney"> {{ (form.totalMoney - form.discountsMoney) ?
                (form.totalMoney -
                  form.discountsMoney).toFixed(2) : '0.00' }}</span> -->
              <span style="font-size: 25px"> {{ form.totalMoney ? form.totalMoney.toFixed(2) : '0.00' }}</span>
            </div>
          </div>
          <div class="infoDiv flex-end">
            <!-- <div v-if="form.patientId">
              <span class="t1">患者：李钢</span>
              <span class="t1">性别：男</span>
              <span class="t1">年龄：32岁</span>
              <span class="t1">身份证号：31082319911005006X</span>
              <span class="t1">联系方式：17635467855</span>
            </div>
            <div v-else>请添加患者信息</div> -->
            <div>
              <!-- <el-button type="primary" size="small" @click="addPatient" plain>输入患者信息</el-button> -->
              <!-- <el-radio-group v-model="isPrint" style="margin-right: 20px;">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group> -->
              <el-button type="primary" size="small" @click="toCharge">收费</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="DialogTitle" :visible.sync="setDialog" width="30%">
      <el-form class="dialog-form" :model="form" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="应收金额" style="color: #ff0000" class="numT">
          <!-- <span style="font-size: 25px;" v-if="form.discountsMoney"><span style="font-size: 14px;">￥</span>{{
            form.totalMoney.toFixed(2) - form.discountsMoney.toFixed(2) }}</span> -->
          <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ form.totalMoney.toFixed(2) }}</span>
        </el-form-item>
        <!-- <el-form-item label="挂账">
          <el-switch v-model="form.credit" @change="handleChange"></el-switch>
        </el-form-item> -->
        <template v-if="form.credit">
          <el-form-item label="姓名" prop="debtUserName">
            <el-input v-model="form.debtUserName" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="debtUserPhone">
            <el-input v-model="form.debtUserPhone" style="width: 80%" clearable></el-input>
          </el-form-item>
          <el-form-item label="挂账金额" prop="debtMoney">
            <el-input-number v-model="form.debtMoney" size="small" controls-position="right" :precision="2" :step="1" :min="1" class="leftInput" @change="checkDebtMoney" style="width: 150px"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="付款方式">
          <el-radio-group v-model="form.payWay">
            <el-radio :label="0">现金</el-radio>
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2">支付宝</el-radio>
            <el-radio :label="3">银行卡</el-radio>
            <el-radio :label="5">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="付款金额" prop="payment">
          <el-input-number v-model="form.payment" size="small" controls-position="right" :precision="2" :step="1" class="leftInput" @change="checkMoney" style="width: 150px"></el-input-number>
        </el-form-item>
        <el-form-item label="找零">
          {{ form.change ? form.change.toFixed(2) : '0.00' }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="reload()">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('chargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <div ref="pdfDom" style="width: 100%; display: none" :class="isShowPrint ? 'printBox' : ''">
      <div style="width: 80mm; padding-top: 20px; font-size: 13px !important">
        <h3 style="text-align: center; margin: 20px 0">收费清单</h3>
        <el-table border :data="form.details" show-summary :summary-method="getSummaries">
          <el-table-column prop="entityName" label="药品名称"></el-table-column>
          <el-table-column prop="money" label="单价" align="center">
            <template slot-scope="scope"> {{ scope.row.money ? scope.row.money.toFixed(2) : '0.00' }}/{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.specificationUnit }} </template>
          </el-table-column>
          <el-table-column prop="amount" label="数量" align="center"></el-table-column>
          <el-table-column prop="countMoney" label="总金额（元）" align="center">
            <template slot-scope="scope">
              {{ scope.row.countMoney ? scope.row.countMoney.toFixed(2) : '0.00' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient'
import printJS from 'print-js'
import html2Canvas from 'html2canvas'
export default {
  inject: ['reload'],
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      tableData: [],
      addData: {},
      DialogTitle: '收费',
      setDialog: false,
      formLabelWidth: '100px',
      isPrint: false,
      isShowPrint: false,
      isSave: false,
      form: {
        patientId: 0,
        discountsMoney: 0, //优惠金额
        totalMoney: 0, //总金额
        userPayMoney: 0, //相对订单用户实付金额
        details: [
          {
            inventoryNumber: 1,
            orderTyp: 0, //0直接售药 1处方 2检查治疗项 3附加收费
            entityId: null,
            entityName: null,
            purchasePrice: undefined,
            price: undefined,
            money: undefined,
            amount: undefined,
            countMoney: null,
            productFactory: null,
            isSeparate: false,
            specification: null,
            specificationUnit: null,
            separateNumberUnit: null,
          },
        ],
        credit: false,
        payWay: 0, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        debtUserName: null,
        debtUserPhone: null,
        debtMoney: 0, //挂账总金额
        payment: null, //输入金额
        payMoney: 0, //支付金额
        change: 0, //找零
      },
      orderId: this.$route.query.orderId,
      rules: {
        entityId: [{ required: true, message: '请输入药品名称', trigger: 'blur' }],
        money: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入开药量', trigger: 'blur' }],
        debtUserName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        debtUserPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号', trigger: 'blur' },
        ],
        debtMoney: [{ required: true, message: '请输入挂账金额', trigger: 'blur' }],
        payment: [{ required: true, message: '请输入付款金额', trigger: 'blur' }],
      },
      isLoading: false,
      options: [],
      printDialog: false,
    }
  },
  watch: {
    form: {
      handler(newValue) {
        let totalUserPayMoney = 0
        this.form.details.forEach((item) => {
          item.countMoney = item.money * item.amount
          totalUserPayMoney = totalUserPayMoney + item.countMoney
        })
        this.form.totalMoney = totalUserPayMoney
        this.form.userPayMoney = totalUserPayMoney
      },
      deep: true,
    },
  },
  methods: {
    toPrint() {
      this.isShowPrint = true
      this.$nextTick(() => {
        html2Canvas(this.$refs.pdfDom, {
          allowTaint: true,
          taintTest: false,
          useCORS: true,
          dpi: window.devicePixelRatio * 4,
          scale: 4,
        }).then((canvas) => {
          const url = canvas.toDataURL()
          printJS({
            printable: url, // 要打印的id
            type: 'image',
            style: '@page{size:auto;margin: 0cm 1cm 0cm 1cm;}', //去除页眉页脚
          })
          this.isShowPrint = false
          this.isPrint = false
        })
      })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (column.property == 'countMoney') {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 元'
          } else {
            sums[index] = ' '
          }
        }
      })

      return sums
    },
    checkDebtMoney() {
      this.form.payment = this.form.totalMoney - this.form.discountsMoney - this.form.debtMoney
      this.form.change = 0
    },
    checkMoney() {
      if (this.form.credit) {
        let payMoney = this.form.totalMoney - this.form.discountsMoney - this.form.debtMoney
        if (this.form.payment < payMoney) {
          this.$message({
            message: `付款金额不能小于${payMoney}`,
            type: 'error',
          })
          this.form.payment = undefined
          return
        } else {
          this.form.change = this.form.payment + this.form.debtMoney - (this.form.totalMoney - this.form.discountsMoney)
        }
      } else {
        if (this.form.payment < this.form.userPayMoney) {
          this.$message({
            message: `付款金额不能小于${this.form.userPayMoney}`,
            type: 'error',
          })
          this.form.payment = undefined
          return
        } else {
          this.form.change = this.form.payment - (this.form.totalMoney - this.form.discountsMoney)
        }
      }
    },
    toCharge() {
      if (!this.form.details[0].entityId) {
        this.$message({
          message: '请添加出售药品',
          type: 'warning',
        })
        return
      }
      for (let index = 0; index < this.form.details.length; index++) {
        let item = this.form.details[index]
        if (item.amount > item.inventoryNumber) {
          this.$confirm(`${item.entityName}仅剩余${item.inventoryNumber}，请补充库存！`, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
          })
          return
        } else if (!item.isSeparate && item.purchasePrice > item.money) {
          this.$confirm('售价小于进货价，继续操作', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
          })
            .then(() => {
              this.form.payment = this.form.totalMoney - this.form.discountsMoney
              this.form.payMoney = this.form.totalMoney - this.form.discountsMoney
              this.form.change = null
              this.setDialog = true
              return
            })
            .catch(() => {
              return
            })
        } else {
          this.form.payment = this.form.totalMoney - this.form.discountsMoney
          this.form.payMoney = this.form.totalMoney - this.form.discountsMoney
          this.form.change = null
          this.setDialog = true
          return
        }
      }
    },
    handleChange() {
      this.form.debtUserName = null
      this.form.debtUserPhone = null
      this.form.debtMoney = undefined
      this.form.change = null
      if (!this.form.credit) {
        this.form.payment = this.form.totalMoney - this.form.discountsMoney
        this.form.debtMoney = 0
      }
    },
    handleSelect(e, map) {
      let item = this.options.filter((i) => i.id == e)[0]
      if (item) {
        map.orderType = 0
        map.entityName = item.mzDrug.drugName
        map.purchasePrice = item.purchasePrice
        map.price = item.tradingPrice
        map.money = item.tradingPrice ? item.tradingPrice : undefined
        map.amount = 1
        map.countMoney = map.money
        map.productFactory = item.mzDrug.productFactory
        map.isSeparate = item.isSeparate
        map.specification = item.mzDrug.specification
        map.specificationUnit = item.mzDrug.specificationUnit
        map.inventoryNumber = item.inventoryNumber
        map.separateNumberUnit = item.separateNumberUnit
      }
    },
    remoteMethod(queryString) {
      var _this = this
      this.options = []
      if (queryString !== '') {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
          _this.OutpatientDomain.DrugByOrgIn(
            queryString,
            function (res) {
              _this.options = res.data
            },
            function (error) {
              console.log('药品列表请求失败!请刷新重试:', error)
            }
          )
        }, 200)
      }
    },
    CloseBtn(formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
    SaveBtn(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            background: 'rgba(0, 0, 0, 0.5)',
          })
          _this.form.payMoney = this.form.totalMoney - this.form.discountsMoney - this.form.debtMoney
          _this.OutpatientDomain.DrugSell(
            _this.form,
            function (data) {
              loading.close()
              _this.$message({
                message: '收费成功',
                type: 'success',
              })
              if (_this.isPrint) {
                _this.form = {
                  patientId: 0,
                  discountsMoney: 0, //优惠金额
                  totalMoney: 0, //总金额
                  userPayMoney: 0, //相对订单用户实付金额
                  details: [
                    {
                      inventoryNumber: 1,
                      orderTyp: 0, //0直接售药 1处方 2检查治疗项 3附加收费
                      entityId: 0,
                      entityName: null,
                      purchasePrice: undefined,
                      price: undefined,
                      money: undefined,
                      amount: undefined,
                      countMoney: null,
                      productFactory: null,
                      isSeparate: false,
                      specification: null,
                      specificationUnit: null,
                      separateNumberUnit: null,
                    },
                  ],
                  credit: false,
                  payWay: 0, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
                  debtUserName: null,
                  debtUserPhone: null,
                  debtMoney: 0, //挂账总金额
                  payment: null, //输入金额
                  payMoney: 0, //支付金额
                  change: 0, //找零
                }
                _this.setDialog = false
                _this.toPrint()
              } else {
                _this.reload()
              }
            },
            function (error) {
              loading.close()
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleReset() {
      let row = {
        orderTyp: 0,
        entityId: 0,
        entityName: null,
        purchasePrice: undefined,
        price: undefined,
        money: undefined,
        amount: undefined,
        countMoney: null,
        productFactory: null,
        specification: null,
        inventoryNumber: 0,
      }
      this.form.discountsMoney = 0
      this.form.details = []
      this.form.details.push(row)
    },
    handleAdd() {
      let row = {
        orderTyp: 0,
        entityId: 0,
        entityName: null,
        purchasePrice: 0,
        price: 0,
        money: null,
        amount: 1,
        countMoney: null,
        productFactory: null,
        specification: null,
        inventoryNumber: 0,
      }
      this.form.details.push(row)
      this.options = []
    },
    handleDelete(row, index) {
      if (this.form.details.length == 1) {
        this.$message({
          message: '请至少保留一行',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      this.form.details.splice(index, 1)
    },
    goBack() {
      this.$router.go(-1)
    },
    addPatient() {
      this.$router.push({
        path: './AddPatient',
        query: {},
      })
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    // padding-left: 20px;
    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      // border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__body-wrapper .el-table__cell {
      padding: 2px 0 !important;
    }

    // ::v-deep .el-form-item{
    //   margin-bottom: 0 !important;

    // }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }

    .el-form-item .el-form-item {
      margin-bottom: 20px;
    }

    .leftInput {
      width: 100%;
      position: relative;
    }

    .rightTip {
      position: absolute;

      top: 0;
      z-index: 2;
      color: #999999;
    }

    ::v-deep .el-input-number .el-input__inner {
      text-align: left;
    }

    .numDiv {
      padding: 15px;
      background: #fff7f7;
      border: 1px solid #f4d3d3;
      border-radius: 8px;
      margin: 0 20px 20px 20px;

      .num1 {
        margin: 0 10px;
      }

      ::v-deep .num2 .el-input__inner {
        color: #ff0000;
      }

      .inputNum {
        color: #ff0000;
      }
    }

    .infoDiv {
      margin: 0 20px;

      .t1 {
        margin-right: 20px;
      }
    }
  }

  .selectDrug {
    position: relative;
  }

  .warningTip {
    color: orange;
    position: absolute;
    top: 15px;
    right: 55px;
  }

  .tipMsg {
    position: absolute;
    top: 35px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    margin-top: -5px;
    padding: 0 5px;
    display: none;
    height: 25px;
    line-height: 25px;

    span {
      color: red;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .warningTip:hover + .tipMsg {
    display: block;
  }

  .tipMsg:hover {
    display: block;
  }
}

.dialog-form .numT ::v-deep .el-form-item__label {
  color: #ff0000;
}
</style>
